import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import appConfig from '../../../app-config';

const styles = theme => ({
  labelPlacementStart: {marginLeft: 0},
  root: {
    width: 48,
    height: 24,
    padding: 0,
    marginLeft: '158px',
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(24px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.toggleColor.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
    '&$disabled': {
      backgroundColor: 'red',
    },
  },
  thumb: {
    width: 22,
    height: 22,
  },
  track: {
    borderRadius: 24 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
    // transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {
    '&$disabled': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  disabled: {
    '&$checked': {
      transform: 'translateX(24px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.grey[400],
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
    '&$disabled': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  focusVisible: {},
});

const SwitchComponent = ({
  classes,
  checked,
  onChange,
  label,
  name,
  ...rest
}) => (
  <div style={{width: 'fit-content'}}>
    <FormControlLabel
      classes={{
        labelPlacementStart: classes.labelPlacementStart,
      }}
      control={
        <Switch
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
            disabled: classes.disabled,
          }}
          disableRipple
          checked={checked}
          onChange={onChange}
          name={name}
          {...rest}
        />
      }
      label={
        <span style={{fontFamily: appConfig.styleConfig.primaryFontFontFamily}}>
          {label}
        </span>
      }
      labelPlacement="start"
    />
  </div>
);

export default withStyles(styles)(SwitchComponent);
