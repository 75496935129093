import React, { Fragment, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Formik, Field } from 'formik';
import TextField from '@material-ui/core/TextField';
import {
  Subhead,
  Headline,
  Title3,
  Title1,
} from '../../../components/Typography';
import { EditIcon } from '../../../components/Icons';
import {
  FlatButtonRow,
  FlatButton,
  PrimaryButton,
} from '../../../components/Buttons';
import Switch from '../../../components/Form/Switch';
import ChangePasswordContainer from '../../ChangePassword/ChangePasswordContainer';
import DeleteAccountContainer from '../../DeleteAccount/DeleteAccountContainer';
import ProfileSettingsNewForm from './ProfileSettingsNewForm';
import Dialog from '../../../components/Dialog/Dialog';
import CloseButton from '../../../components/CloseButton/CloseButton';
import SingleDatePicker from '../../../components/SingleDatePicker';
import moment from 'moment';
import appConfig from '../../../../app-config';
import PhoneNumber from '../../../layout/components/PhoneNumber';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  border-radius: 6px;
  width: 100%;
`;

const FieldsWrapper = styled.div`
  display: grid;
  grid-gap: 44px;
`;
const PhoneProfileMain = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  min-width: 100%;
  flex-shrink: 0;
  span {
    color: rgba(0, 0, 0, 0.35);
    display: block;
    position: absolute;
    bottom: 4px;
    font-size: 16px;
    font-weight: 400;
  }
  #ProfileSettings-MobileNumberField {
    width: 100%;
  }
`;
const SaveMessage = styled(Subhead)`
  color: ${(props) => props.theme.darkGray};
  font-weight: 440;
`;

const UneditableStyledTextField = styled(TextField)`
  label {
    color: ${(props) => props.theme.profileSettingsHardDisableLabelColor};
    font-family: ${(props) => props.theme.primaryFontFontFamily};
  }

  div {
    input {
      color: ${(props) => props.theme.profileSettingsHardDisableInputColor};
    }
    input:disabled {
      -webkit-text-fill-color: ${(props) =>
        props.theme.profileSettingsHardDisableInputColor};
    }
  }
`;

const StyledTextField = styled(TextField)`
  label {
    color: ${(props) =>
      props.disabled
        ? props.theme.profileSettingsHardDisableLabelColor
        : props.theme.profileSettingsLabelColor} !important;
    font-family: ${(props) => props.theme.primaryFontFontFamily};
  }

  div {
    input {
      color: ${(props) =>
        props.disabled
          ? props.theme.profileSettingsHardDisableInputColor
          : props.theme.profileSettingsInputColor};
    }
    input:disabled  {
      -webkit-text-fill-color: ${(props) =>
        props.theme.profileSettingsHardDisableInputColor};
    }
  }
`;

const ToggleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
  align-items: center;
  margin-top: 33px;
`;

const styles = (theme) => ({
  input: {
    fontFamily: theme.typography.fontFamily.main,
  },
  scrollPaper: {},
  paper: {
    // margin: 0,
    // // maxHeight: '90%',
    // overflowY: 'visible',
    width: '440px',
    borderRadius: '10px',
  },
  root: {
    width: '100%',
  },
});
const EditIconEnableForm = styled.div`
  // @media (min-width: 1440px) {
  //   display: none;
  // }
`;

const EditIconNewForm = styled.div`
  // @media (max-width: 1439px) {
  //   display: none;
  // }
  display: none;
`;

const MainDiv = styled.div`
  @media (min-width: 1440px) {
    display: grid;
    grid-template-columns: 350px 350px;
    grid-gap: 150px;
  }
`;

const NewFormDiv = styled.div`
  // @media (max-width: 1439px) {
  //   display: none;
  // }
  display: none;
`;

const FlatButtonWrapper = styled(FlatButton)`
  color: ${(props) => props.color || props.theme.clickableTextColor};

  &:hover {
    color: ${(props) => props.theme.clickableTextColorHover};
    transition: all 0.25s ease;
  }
`;

const EditDiv = styled.div`
  font-family: ${(props) => props.theme.primaryFontFontFamily};
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
`;

const BirthdayModalWrapper = styled.div`
  padding: 24px;
  display: grid;
  grid-gap: 24px;
`;

const TitleCancelWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
`;

const ProfileSettingsForm = ({
  handleCancel,
  handleSubmit,
  customerProfile,
  editProfileSettingsIsSuccessful,
  profileSettingsFormIsEnabled,
  setProfileSettingsFormIsEnabled,
  isRequesting,
  setChangePasswordIsVisible,
  setDeleteAccountIsVisible,
  classes,
  showNewForm,
  setShowNewForm,
  profileSettingsNewFormIsEnabled,
  setProfileSettingsNewFormIsEnabled,
  editToggleValue,
  isBirthdayEnabled,
  saveBirthday,
  birthdayModalIsVisible,
  requestingSaveBirthday,
  openBirthdayModal,
  closeBirthdayModal,
}) => {
  const {
    first_name,
    last_name,
    mobile_phone,
    email,
    birthdate,
    preferences: { email_marketing, sms_marketing },
    company_name,
  } = customerProfile;

  const initialEmailMarketing = customerProfile.preferences.email_marketing;
  const initialSmsMarketing = customerProfile.preferences.sms_marketing;

  const [emailMarketing, setEmailMarketing] = useState(
    customerProfile.preferences.email_marketing
  );

  const [smsMarketing, setSmsMarketing] = useState(
    customerProfile.preferences.sms_marketing
  );

  const handleSaveBirthday = (birthdate) => {
    saveBirthday(birthdate);
  };

  return (
    <MainDiv>
      <div>
        <Formik
          initialValues={{
            first_name,
            last_name,
            company_name,
            // mobile_phone,
            // email,
            // email_marketing,
            //sms_marketing,
          }}
          validate={(values) => {
            let errors = {};
            if (!values.first_name) {
              errors.first_name = 'Required';
            }
            if (!values.last_name) {
              errors.last_name = 'Required';
            }
            return errors;
          }}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => (
            <Fragment>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '15px',
                  marginTop: '5px',
                }}
              >
                <div>
                  <Title3
                    id="ProfileSettings-Title"
                    style={{ fontWeight: '600', letterSpacing: '0.03' }}
                  >
                    Personal Details
                  </Title3>
                </div>
                {profileSettingsFormIsEnabled ? (
                  <FlatButtonRow style={{ width: '140px' }}>
                    <FlatButton
                      id="ProfileSettings-ResetFormButton"
                      onClick={() => {
                        handleCancel();
                        resetForm();
                      }}
                      type="button"
                      secondary
                      disabled={isRequesting}
                    >
                      Cancel
                    </FlatButton>
                    <FlatButtonWrapper
                      id="ProfileSettings-SaveButton"
                      onClick={handleSubmit}
                      type="submit"
                      disabled={isRequesting}
                      showLoading={isRequesting}
                    >
                      Save
                    </FlatButtonWrapper>
                  </FlatButtonRow>
                ) : (
                  <EditDiv>
                    <EditIconNewForm>
                      <EditIcon
                        id="ProfileSettings-NewForm"
                        onClick={() => {
                          setProfileSettingsNewFormIsEnabled(true);
                          setShowNewForm(true);
                        }}
                      />
                    </EditIconNewForm>
                    <EditIconEnableForm>
                      <EditIcon
                        id="ProfileSettings-EnableForm"
                        onClick={() => setProfileSettingsFormIsEnabled(true)}
                      />
                    </EditIconEnableForm>
                    <span
                      id="ProfileSettings-EditForm"
                      onClick={() => setProfileSettingsFormIsEnabled(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      Edit
                    </span>
                  </EditDiv>
                )}
              </div>
              <form onSubmit={(values) => handleSubmit(values)}>
                <Wrapper>
                  <FieldsWrapper>
                    <StyledTextField
                      id="ProfileSettings-FirstNameField"
                      label="First Name"
                      name="first_name"
                      type="text"
                      autoComplete="fname"
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!profileSettingsFormIsEnabled}
                      error={
                        touched.first_name && errors.first_name ? true : false
                      }
                      helperText={touched.first_name && errors.first_name}
                    />
                    <StyledTextField
                      id="ProfileSettings-LastNameField"
                      label="Last Name"
                      name="last_name"
                      type="text"
                      autoComplete="lname"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!profileSettingsFormIsEnabled}
                      error={
                        touched.last_name && errors.last_name ? true : false
                      }
                      helperText={touched.last_name && errors.last_name}
                    />
                    {appConfig.appSource === 'madradish_catering' ? (
                      <StyledTextField
                        id="ProfileSettings-CompanyNameField"
                        label="Company Name - Optional"
                        name="company_name"
                        type="text"
                        value={values.company_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          className: classes.input,
                          required: false,
                        }}
                        disabled={!profileSettingsFormIsEnabled}
                        hardDisable={profileSettingsFormIsEnabled}
                      />
                    ) : null}
                    <UneditableStyledTextField
                      id="ProfileSettings-EmailField"
                      label="Email"
                      // name="email"
                      type="email"
                      // autoComplete="email"
                      value={email}
                      // onChange={handleChange}
                      disabled={profileSettingsFormIsEnabled}
                      InputLabelProps={{ shrink: true }}
                    />
                    <PhoneProfileMain>
                      <span>+1</span>
                      <PhoneNumber
                        id="ProfileSettings-MobileNumberField"
                        label="Mobile Number"
                        name="mobile_phone"
                        defaultPhoneValue={mobile_phone}
                        disabled
                        notVisible
                        hardDisable={profileSettingsFormIsEnabled}
                        handlePhone={(value) =>
                          setFieldValue('mobile_phone', value)
                        }
                      />
                    </PhoneProfileMain>
                    {/* <UneditableStyledTextField
                      id="ProfileSettings-MobileNumberField"
                      label="Mobile Number"
                      // name="mobile_phone"
                      type="tel"
                      // autoComplete="tel"
                      value={mobile_phone}
                      InputLabelProps={{ shrink: true }}
                      // onChange={handleChange}
                      disabled
                      hardDisable={profileSettingsFormIsEnabled}
                    /> */}
                    {isBirthdayEnabled ? (
                      <StyledTextField
                        id="ProfileSettings-birthdate"
                        label={birthdate ? 'Birthday' : 'Add Birthday'}
                        // name="mobile_phone"
                        type="text"
                        // autoComplete="tel"
                        value={birthdate}
                        InputLabelProps={{ shrink: birthdate ? true : false }}
                        // onChange={handleChange}
                        disabled={birthdate ? true : false}
                        onClick={() => {
                          !birthdate && openBirthdayModal();
                        }}
                        hardDisable={profileSettingsFormIsEnabled}
                      />
                    ) : null}
                  </FieldsWrapper>
                </Wrapper>
              </form>
            </Fragment>
          )}
        </Formik>

        <ToggleWrapper>
          <Headline id="ProfileSettings-EmailCommunication">
            Receive email communication
          </Headline>
          <Switch
            id="ProfileSettings-EmailMarketing"
            name="email_marketing"
            value={emailMarketing}
            checked={emailMarketing}
            onChange={(e) => {
              setEmailMarketing(e.target.checked);
              editToggleValue(e.target.checked, smsMarketing);
            }}
          />
        </ToggleWrapper>
        <ToggleWrapper>
          <Headline id="ProfileSettings-ReceiveSMS">
            Receive text communication
          </Headline>
          <Switch
            id="ProfileSettings-SMSMarketing"
            name="sms_marketing"
            value={smsMarketing}
            checked={smsMarketing}
            onChange={(e) => {
              setSmsMarketing(e.target.checked);
              editToggleValue(emailMarketing, e.target.checked);
            }}
          />
        </ToggleWrapper>
        <div style={{ marginRight: 'auto', marginTop: '33px' }}>
          <Headline
            id="ProfileSettings-ChangePasswordHeadline"
            style={{ cursor: 'pointer' }}
            onClick={() => setChangePasswordIsVisible(true)}
          >
            Change Password
          </Headline>
        </div>
        <div style={{ marginRight: 'auto', marginTop: '33px' }}>
          <Headline
            id="ProfileSettings-ChangePasswordHeadline"
            style={{ cursor: 'pointer' }}
            onClick={() => setDeleteAccountIsVisible(true)}
          >
            Delete Account
          </Headline>
        </div>
        <ChangePasswordContainer />
        <DeleteAccountContainer />
      </div>
      <NewFormDiv>
        {showNewForm ? (
          <ProfileSettingsNewForm
            handleCancel={handleCancel}
            handleSubmit={handleSubmit}
            customerProfile={customerProfile}
            profileSettingsFormIsEnabled={true}
            setProfileSettingsFormIsEnabled={setProfileSettingsFormIsEnabled}
            isRequesting={isRequesting}
            setChangePasswordIsVisible={setChangePasswordIsVisible}
            setDeleteAccountIsVisible={setDeleteAccountIsVisible}
            setShowNewForm={setShowNewForm}
            setProfileSettingsNewFormIsEnabled={
              setProfileSettingsNewFormIsEnabled
            }
            profileSettingsNewFormIsEnabled={profileSettingsNewFormIsEnabled}
            emailMarketing={emailMarketing}
            smsMarketing={smsMarketing}
            initialSmsMarketing={initialSmsMarketing}
            setSmsMarketing={setSmsMarketing}
            initialEmailMarketing={initialEmailMarketing}
            setEmailMarketing={setEmailMarketing}
            isBirthdayEnabled={isBirthdayEnabled}
          />
        ) : null}
        <Dialog
          open={birthdayModalIsVisible}
          classes={classes}
          onBackdropClick={() => {
            closeBirthdayModal();
          }}
          scroll="body"
        >
          <Formik
            initialValues={{
              birthdate,
            }}
            validate={(values) => {
              let errors = {};

              return errors;
            }}
          >
            {({ values, handleChange }) => (
              <BirthdayModalWrapper>
                <TitleCancelWrapper>
                  <Title1>Add Birthday</Title1>
                  <CloseButton
                    onClick={() => {
                      closeBirthdayModal();
                    }}
                  />
                </TitleCancelWrapper>
                <Field
                  id="RegistrationForm-BirthdateField"
                  name="birthdate"
                  component={SingleDatePicker}
                  label="Birthday"
                  clearable
                  disableFuture
                  openTo="year"
                  format={'dd/MM/yyyy'}
                  views={['year', 'month', 'date']}
                  InputLabelProps={{
                    className: classes.input,
                  }}
                  onChange={handleChange}
                  value={values.birthdate}
                />
                <PrimaryButton
                  onClick={() => handleSaveBirthday(values.birthdate)}
                >
                  Save
                </PrimaryButton>
              </BirthdayModalWrapper>
            )}
          </Formik>
        </Dialog>
      </NewFormDiv>
    </MainDiv>
  );
};

export default withStyles(styles)(ProfileSettingsForm);
