import React from 'react';
import {connect} from 'react-redux';
import Page404 from './404';
import ProfileSettingsContainer from '../modules/ProfileSettings/ProfileSettingsContainer';

const ProfileSettingsPage = ({isAuthenticated}) => {
  if (isAuthenticated) {
    return <ProfileSettingsContainer />;
  } else {
    return <Page404 />;
  }
};

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.isAuthenticated,
});

export default connect(mapStateToProps)(ProfileSettingsPage);
