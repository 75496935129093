import {
  SET_CHANGE_PASSWORD_IS_VISIBLE,
  POST_CHANGE_PASSWORD_REQUEST,
  POST_CHANGE_PASSWORD_SUCCESS,
  POST_CHANGE_PASSWORD_FAILURE,
} from './changePasswordTypes';
import api from '../../api';
import { createAlert } from '../Alert/alertActions';
import formatedBEErrorMessage from '../../utils/formatedBEErrorMessage';

export function setChangePasswordIsVisible(changePasswordIsVisible) {
  return {
    type: SET_CHANGE_PASSWORD_IS_VISIBLE,
    changePasswordIsVisible,
  };
}
export function postChangePassword(formBody) {
  return function(dispatch) {
    dispatch({ type: POST_CHANGE_PASSWORD_REQUEST });
    dispatch({
      type: 'UPDATE_CHANGE_PASSWORD_ERROR_MESSAGE',
      errors: {},
    });
    api
      .patch(`/customer/password`, formBody, { ignore_interception: true })
      .then((response) => {
        dispatch(
          createAlert({
            type: 'success',
            message: 'Password changed successfully',
          })
        );
        dispatch({ type: POST_CHANGE_PASSWORD_SUCCESS });
      })
      .catch((error) => {
        const errors = formatedBEErrorMessage(error);
        dispatch({
          type: 'UPDATE_CHANGE_PASSWORD_ERROR_MESSAGE',
          errors: errors,
        });
      });
  };
}
