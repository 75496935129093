import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as actions from './changePasswordActions';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import ChangePassword from './ChangePassword';
import StickyErrorMessageBox from '../../components/ErrorMessage/StickyErrorMessageBox';
const ErrorMesageWrapper = styled.div`
  width: 400px;
  padding: 0;
  margin: 0;
  @media (max-width: 786px) {
    width: 100%;
  }
`;

const styles = () => ({
  scrollPaper: {
    alignItems:
      typeof window !== 'undefined' && window.innerWidth < 786
        ? 'flex-end'
        : 'center',
  },
  paper: {
    margin: 0,
    maxWidth: '100%',
    borderRadius: '15px',
    // maxHeight: '95%',
  },
});

let ChangePasswordContainer = ({
  changePasswordIsVisible,
  setChangePasswordIsVisible,
  postChangePassword,
  classes,
  changePasswordIsRequesting,
  minPasswordLength,
  changePasswordErrors,
}) => {
  const [scrollY, setScrollY] = useState(0);
  const [errors, setErrors] = useState({});
  const updateErrors = useCallback(() => {
    setErrors(changePasswordErrors);
  }, [changePasswordErrors]);
  useEffect(() => {
    updateErrors();
  }, [updateErrors]);
  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, []);

  const listener = () => {
    setScrollY(typeof window !== 'undefined' && window.scrollY);
  };

  useEffect(() => {
    if (!changePasswordIsVisible) window.scrollTo(0, scrollY);
  }, [changePasswordIsVisible]);

  return (
    <Dialog
      aria-labelledby="change-password"
      aria-describedby="change-password"
      open={changePasswordIsVisible}
      onBackdropClick={() => setChangePasswordIsVisible(false)}
      fullWidth={typeof window !== 'undefined' && window.innerWidth < 786}
      fullScreen={typeof window !== 'undefined' && window.innerWidth < 786}
      PaperProps={{ square: false, classes: { root: classes.paper } }}
      className={classes.paper}
      scroll="body"
    >
      {errors && Object.keys(errors).length > 0 && (
        <ErrorMesageWrapper>
          <StickyErrorMessageBox
            errors={errors}
            marginTop="0"
            messageWidth="100%"
          />
        </ErrorMesageWrapper>
      )}
      <ChangePassword
        setChangePasswordIsVisible={setChangePasswordIsVisible}
        postChangePassword={postChangePassword}
        isRequesting={changePasswordIsRequesting}
        minPasswordLength={minPasswordLength}
        changePasswordErrors={changePasswordErrors}
      />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  changePasswordIsVisible: state.changePasswordReducer.changePasswordIsVisible,
  changePasswordIsRequesting:
    state.changePasswordReducer.changePasswordIsRequesting,
  minPasswordLength: state.authReducer.appConfig.password_complexity_min_length,
  changePasswordErrors: state.changePasswordReducer.changePasswordErrors,
});

export default withStyles(styles)(
  connect(mapStateToProps, actions)(ChangePasswordContainer)
);
