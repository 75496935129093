import api from "../../api";
import { createAlert } from "../Alert/alertActions";
import formatedBEErrorMessage from "../../utils/formatedBEErrorMessage";
import appConfig from "../../../app-config";
import { navigate } from "gatsby";

export function setDeleteAccountIsVisible(changeAccountIsVisible) {
  return {
    type: "SET_CHANGE_ACCOUNT_IS_VISIBLE",
    payload: changeAccountIsVisible,
  };
}

export function postDeleteAccount(formBody) {
  let formdata = { password: formBody.confirm_password };
  return function(dispatch) {
    dispatch({
      type: "SET_DELETE_ACCOUNT_ERROR_MESSAGE",
      errors: {},
    });
    api
      .post(`/customer/delete`, formdata, { ignore_interception: true })
      .then((response) => {
        navigate("/");
        dispatch(
          createAlert({
            type: "success",
            message: "Account Deleted",
          })
        );

        dispatch({ type: "POST_CHANGE_PASSWORD_SUCCESS" });
      })
      .catch((error) => {
        const errors = formatedBEErrorMessage(error);
        dispatch({
          type: "SET_DELETE_ACCOUNT_ERROR_MESSAGE",
          errors: errors,
        });
      });
  };
}
