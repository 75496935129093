import React from 'react';
import styled from 'styled-components';

const CloseDiv = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const CloseWrapper = styled.div``;

const CloseButton = props => {
  return (
    <CloseWrapper>
      <CloseDiv style={{cursor: 'pointer'}} {...props}>
        <i className="fal fa-times" />
      </CloseDiv>
    </CloseWrapper>
  );
};

export default CloseButton;
