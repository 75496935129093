import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import { Subhead, Headline, Title3 } from '../../../components/Typography';
import { FlatButtonRow, FlatButton } from '../../../components/Buttons';
import appConfig from '../../../../app-config';
const Wrapper = styled.div``;

const FieldsWrapper = styled.div`
  display: grid;
  grid-gap: 44px;
`;

const UneditableStyledTextField = styled(TextField)`
  label {
    color: ${(props) => props.theme.profileSettingsHardDisableLabelColor};
  }

  div {
    input {
      color: ${(props) => props.theme.profileSettingsHardDisableInputColor};
    }
  }
`;

const StyledTextField = styled(TextField)`
  label {
    color: ${(props) =>
      props.disabled
        ? props.theme.profileSettingsHardDisableLabelColor
        : props.theme.profileSettingsLabelColor} !important;
  }

  div {
    input {
      color: ${(props) =>
        props.disabled
          ? props.theme.profileSettingsHardDisableInputColor
          : props.theme.profileSettingsInputColor};
    }
  }
`;

const FlatButtonWrapper = styled(FlatButton)`
  color: ${(props) => props.theme.primaryButtonFontColor};
`;

const ProfileSettingsNewForm = ({
  handleCancel,
  handleSubmit,
  customerProfile,
  profileSettingsFormIsEnabled,
  isRequesting,
  setShowNewForm,
  setProfileSettingsNewFormIsEnabled,
  values,
  emailMarketing,
  smsMarketing,
  initialSmsMarketing,
  setSmsMarketing,
  initialEmailMarketing,
  setEmailMarketing,
  isBirthdayEnabled,
}) => {
  const { first_name, last_name, mobile_phone, email } = customerProfile;

  const email_marketing = emailMarketing;
  const sms_marketing = smsMarketing;

  return (
    <Wrapper>
      <div>
        <Formik
          initialValues={{
            first_name,
            last_name,
            email_marketing,
            sms_marketing,
          }}
          validate={(values) => {
            let errors = {};
            if (!values.first_name) {
              errors.first_name = 'Required';
            }
            if (!values.last_name) {
              errors.last_name = 'Required';
            }
            return errors;
          }}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            resetForm,
          }) => (
            <Fragment>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '15px',
                  height: '20px',
                }}
              >
                <div>
                  <Title3 style={{ fontWeight: '600', letterSpacing: '0.03' }}>
                    Personal Details
                  </Title3>
                </div>
                <FlatButtonRow style={{ width: '140px' }}>
                  <FlatButton
                    id="ProfileSettings-NewFormCancelButton"
                    onClick={() => {
                      setShowNewForm(false);
                      handleCancel();
                      setEmailMarketing(initialEmailMarketing);
                      setSmsMarketing(initialSmsMarketing);
                      resetForm();
                      setProfileSettingsNewFormIsEnabled(false);
                    }}
                    type="button"
                    secondary
                    disabled={isRequesting}
                  >
                    Cancel
                  </FlatButton>
                  <FlatButtonWrapper
                    id="ProfileSettings-NewFormSaveButton"
                    onClick={handleSubmit}
                    type="submit"
                    disabled={isRequesting}
                    showLoading={isRequesting}
                  >
                    Save
                  </FlatButtonWrapper>
                </FlatButtonRow>
              </div>
              <form>
                <Wrapper>
                  <FieldsWrapper>
                    <StyledTextField
                      id="ProfileSettings-NewFormFirstNameField"
                      label="First Name"
                      name="first_name"
                      type="text"
                      autoComplete="fname"
                      value={values.first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!profileSettingsFormIsEnabled}
                      error={
                        touched.first_name && errors.first_name ? true : false
                      }
                      helperText={touched.first_name && errors.first_name}
                    />
                    <StyledTextField
                      id="ProfileSettings-NewFormlastNameField"
                      label="Last Name"
                      name="last_name"
                      type="text"
                      autoComplete="lname"
                      value={values.last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!profileSettingsFormIsEnabled}
                      error={
                        touched.last_name && errors.last_name ? true : false
                      }
                      helperText={touched.last_name && errors.last_name}
                    />
                    {appConfig.appSource === 'madradish_catering' ? (
                      <TextField
                        id="ProfileSettings-CompanyNameField"
                        label="Company Name - Optional"
                        name="company_name"
                        type="text"
                        value={values.company_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputLabelProps={{
                          required: false,
                        }}
                      />
                    ) : null}
                    <UneditableStyledTextField
                      id="ProfileSettings-NewFormEmailField"
                      label="Email"
                      // name="email"
                      type="email"
                      // autoComplete="email"
                      value={email}
                      // onChange={handleChange}
                      disabled
                      InputLabelProps={{ shrink: true }}
                      hardDisable={profileSettingsFormIsEnabled}
                    />
                    <UneditableStyledTextField
                      id="ProfileSettings-NewFormMobileNumberField"
                      label="Mobile Number"
                      // name="mobile_phone"
                      type="tel"
                      // autoComplete="tel"
                      value={mobile_phone}
                      InputLabelProps={{ shrink: true }}
                      // onChange={handleChange}
                      disabled
                      hardDisable={profileSettingsFormIsEnabled}
                    />
                  </FieldsWrapper>
                </Wrapper>
              </form>
            </Fragment>
          )}
        </Formik>
      </div>
    </Wrapper>
  );
};

export default ProfileSettingsNewForm;
