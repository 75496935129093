import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as actions from './deleteAccountActions';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import DeleteAccount from './DeleteAccount';
import StickyErrorMessageBox from '../../components/ErrorMessage/StickyErrorMessageBox';
const ErrorMesageWrapper = styled.div`
  width: 450px;
  padding: 0;
  margin: 0;
  @media (max-width: 786px) {
    width: 100%;
  }
`;

const styles = () => ({
  paper: {
    margin: 0,
    maxWidth: '100%',
    borderRadius: '15px',
    
    // maxHeight: '95%',
  },
});

let DeleteAccountContainer = ({
  changeAccountIsVisible,
  setDeleteAccountIsVisible,
  classes,
  changePasswordErrors,
  postDeleteAccount
}) => {
  const [scrollY, setScrollY] = useState(0);
  const [errors, setErrors] = useState({});
  const updateErrors = useCallback(() => {
    setErrors(changePasswordErrors);
  }, [changePasswordErrors]);
  useEffect(() => {
    updateErrors();
  }, [updateErrors]);
  
  return (
    <Dialog
      aria-labelledby="change-password"
      aria-describedby="change-password"
      open={changeAccountIsVisible}
      onBackdropClick={() => setDeleteAccountIsVisible(false)}
      fullWidth={typeof window !== 'undefined' && window.innerWidth < 786}
      fullScreen={typeof window !== 'undefined' && window.innerWidth < 786}
      PaperProps={{ square: false, classes: { root: classes.paper } }}
      className={classes.paper}
    >
      {errors && Object.keys(errors).length > 0 && (
        <ErrorMesageWrapper>
          <StickyErrorMessageBox
            errors={errors}
            marginTop="0"
            messageWidth="100%"
          />
        </ErrorMesageWrapper>
      )}
      <DeleteAccount
        setDeleteAccountIsVisible={setDeleteAccountIsVisible}
        postDeleteAccount = {postDeleteAccount}
      />
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  changeAccountIsVisible: state.DeleteAccountReducer.changeAccountIsVisible,
  changePasswordErrors : state.DeleteAccountReducer.changePasswordErrors
});

export default withStyles(styles)(
  connect(mapStateToProps, actions)(DeleteAccountContainer)
);
