import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import * as actions from './profileSettingsActions';
import {setChangePasswordIsVisible} from '../ChangePassword/changePasswordActions';
import {setDeleteAccountIsVisible} from '../DeleteAccount/deleteAccountActions';
import ProfileSettings from './ProfileSettings';

const ProfileSettingsContainer = ({
  profileSettingsReducer,
  editProfileSettings,
  setChangePasswordIsVisible,
  setDeleteAccountIsVisible,
  editToggleValue,
  authReducer,
  saveBirthday,
  openBirthdayModal,
  closeBirthdayModal,
}) => {
  const {
    customerProfile,
    editProfileSettingsIsSuccessful,
    requestingEditProfileSettings,
    birthdayModalIsVisible,
    requestingSaveBirthday,
  } = profileSettingsReducer;

  const [
    profileSettingsFormIsEnabled,
    setProfileSettingsFormIsEnabled,
  ] = useState(false);

  const [showNewForm, setShowNewForm] = useState(false);

  const [
    profileSettingsNewFormIsEnabled,
    setProfileSettingsNewFormIsEnabled,
  ] = useState(true);

  useEffect(() => {
    if (editProfileSettingsIsSuccessful) {
      setProfileSettingsFormIsEnabled(false);
    }
  }, [editProfileSettingsIsSuccessful]);

  const handleCancel = () => setProfileSettingsFormIsEnabled(false);

  const handleSubmit = values => {
    editProfileSettings(values);
    setShowNewForm(false);
    setProfileSettingsNewFormIsEnabled(false);
  };

  const isBirthdayEnabled = authReducer.appConfig.get_birthday;

  return (
    <div>
      <ProfileSettings
        customerProfile={customerProfile}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        editProfileSettingsIsSuccessful={editProfileSettingsIsSuccessful}
        profileSettingsFormIsEnabled={profileSettingsFormIsEnabled}
        setProfileSettingsFormIsEnabled={setProfileSettingsFormIsEnabled}
        requestingEditProfileSettings={requestingEditProfileSettings}
        setChangePasswordIsVisible={setChangePasswordIsVisible}
        setDeleteAccountIsVisible = {setDeleteAccountIsVisible}
        showNewForm={showNewForm}
        setShowNewForm={setShowNewForm}
        profileSettingsNewFormIsEnabled={profileSettingsNewFormIsEnabled}
        setProfileSettingsNewFormIsEnabled={setProfileSettingsNewFormIsEnabled}
        editToggleValue={editToggleValue}
        isBirthdayEnabled={isBirthdayEnabled}
        saveBirthday={saveBirthday}
        birthdayModalIsVisible={birthdayModalIsVisible}
        requestingSaveBirthday={requestingSaveBirthday}
        openBirthdayModal={openBirthdayModal}
        closeBirthdayModal={closeBirthdayModal}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  profileSettingsReducer: state.profileSettingsReducer,
  authReducer: state.authReducer,
});

export default connect(mapStateToProps, {
  ...actions,
  setChangePasswordIsVisible,
  setDeleteAccountIsVisible
})(ProfileSettingsContainer);
