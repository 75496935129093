import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
//import { Title2 } from '../../components/Typography';
import { ButtonRow } from '../../components/Buttons';
import { Formik } from 'formik';
import PasswordField from '../../layout/components/PasswordField';

const Wrapper = styled.div`
width: 450px;
height: 570px;
padding: 25px 24px 24px;
border-radius: 8px;
box-shadow: 0 4px 14px 0 rgba(66, 59, 112, 0.32);
background-color: var(--calcite);
  font-family: ${(props) => props.theme.primaryFontFontFamily};

  @media (max-width: 786px) {
    padding: 24px 20px;
    width: 100%;
  }
  display: grid;
  grid-gap: 20px;
`;
const PrimaryButton = styled.button`
height: ${props => (props.small ? '38px' : '48px')};
  height: ${props => props.buttonHeight};
  min-width: ${props => (props.small ? '114px' : '280px')};
  min-width: ${props => props.buttonWidth};
  color: ${props =>
    props.disabled
      ? props.theme.primaryButtonDisabledFontColor
      : props.theme.primaryButtonFontColor};
  background: ${props =>
    props.disabled
      ? "#ec8787"
      : "#c90e0e"};
  text-transform: ${props => props.theme.primaryButtonTextTransform};
  border-radius: ${props => props.theme.primaryButtonBorderRadius};
  // box-shadow: ${props => props.theme.primaryButtonBoxShadow};
  font-size: ${props => (props.small ? '14px' : '20px')};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: ${props =>
    props.buttonBorderRadius ? props.buttonBorderRadius : '4px'};
  font-family: ${props => props.theme.primaryFontFontFamily};
  font-weight: ${props => props.fontWeight};
  &:hover {
    background: ${props =>
      props.disabled
        ? "#ec8787"
        : "#c90e0e"};
    color: ${props =>
      props.disabled
        ? props.theme.primaryButtonDisabledFontColor
        : props.theme.primaryButtonTextHoverColor};
    transition: all 0.25s ease;
  }
  @media (max-width: 768px) {
    width: 100%;
  }

`;
const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  
`;

const Title2 = styled.p`
  width: 344px;
  height: 68px;
  margin: 0 16px 24px 0;
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--colors-basic-dark);
`;

const Title3 = styled.div`
  width: 392px;
  height: 220px;
  margin: 14px 0 31px;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--colors-basic-dark);
`;
const Title4 = styled.p`
  width: 392px;
  height: 22px;
  font-family: Inter;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: var(--colors-basic-dark);
`;

const Cancel = styled.div`
  width: 32px;
  height: 32px;
  margin: 0 0 60px -60px;
  padding: 4px 8px 4px 9px;
  border-radius: 50%;
  color: white;
  background-color: rgba(0, 0, 0, 0.54);
   
  right: 30px;
  top: 30px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const DeleteAccount = ({
  setDeleteAccountIsVisible,
  isRequesting,
  postDeleteAccount,
  minPasswordLength,
}) => {
  const [oldPasswordVisibility, setOldPasswordVisibility] = useState(false);
  const [newPasswordVisibility, setNewPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(
    false
  );
  const [minLengthCheck, setMinLengthCheck] = useState(false);
  const [oneNumberCheck, setOneNumberCheck] = useState(false);
  const [oneSymbolCheck, setOneSymbolCheck] = useState(false);
  const [oneUpperCheck, setOneUpperCheck] = useState(false);
  const [oneLowerCheck, setOneLowerCheck] = useState(false);
  return (
    <Wrapper>
      <TitleWrapper>
        <Title2 id="ChangePassword-Title">Are you sure you want to delete your account</Title2>
        <Cancel
          
          onClick={() => setDeleteAccountIsVisible(false)}
        >
          <i className="fal fa-times" />
        </Cancel>
        <Title3>
      <Title4 >Account deletion is immediate and cannot be undone.</Title4>
      <Title4 style={{marginTop:"40px"}}>If you change your mind once your account is deleted, you will need to create a new account with a different email and phone number.</Title4> 
      <Title4 style={{marginTop:"60px"}}>Please be advised that certain data may be retained for legal purposes and for the prevention of fraudulent activity.</Title4>
      <Title4 style={{marginTop:"80px"}}>Confirm your password to delete your account:</Title4>

          </Title3>
                </TitleWrapper>
      <Formik
        validate={(values) => {
          let errors = {};

          
          if (values.new_password.length < minPasswordLength) {
            errors.new_password = `Password must be a minimum of ${minPasswordLength} characters`;
            setMinLengthCheck(false);
          } else {
            setMinLengthCheck(true);
          }
          if (!new RegExp('[^A-Za-z0-9]').test(values.new_password)) {
            errors.new_password = 'Password must contain at least one symbol';
            setOneSymbolCheck(false);
          } else {
            setOneSymbolCheck(true);
          }
          if (!new RegExp('[0-9]').test(values.new_password)) {
            errors.new_password = 'Password must contain at least one digit';
            setOneNumberCheck(false);
          } else {
            setOneNumberCheck(true);
          }
          if (!new RegExp('(?=.*[a-z])').test(values.new_password)) {
            errors.new_password =
              'Password must contain at least one lowercase letter';
            setOneLowerCheck(false);
          } else {
            setOneLowerCheck(true);
          }
          if (!new RegExp('(?=.*[A-Z])').test(values.new_password)) {
            errors.new_password =
              'Password must contain at least one uppercase letter';
            setOneUpperCheck(false);
          } else {
            setOneUpperCheck(true);
          }
          if (values.new_password != values.confirm_password) {
            errors.confirm_password = 'Does not match new password';
          }

          if (!values.current_password) {
            errors.current_password = 'Required';
          }
          if (!values.new_password) {
            errors.new_password = 'Required';
          }
          if (!values.confirm_password) {
            errors.confirm_password = 'Required';
          }
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          dirty,
        }) => (
          <Fragment>
            <PasswordField
              id="ChangePassword-ConfirmPassword"
              label="Password"
              name="confirm_password"
              autoComplete="off" 
              value={values.confirm_password}
              showPassword={confirmPasswordVisibility}
              setShowPassword={setConfirmPasswordVisibility}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{width:"390px"}}
              helperText={touched.confirm_password && errors.confirm_password}
              error={
                touched.confirm_password && errors.confirm_password
                  ? true
                  : false
              }
            />
            <ButtonRow justifyContent="flex-center">
              <PrimaryButton
                id="ChangePassword-SubmitButton"
                disabled={Object.keys(errors).length || !dirty}
                type="submit"
                onClick={() => {
                  
                  postDeleteAccount(values)
                }}
                buttonWidth="390px"
                buttonHeight="56px"
                buttonBorderRadius="10px"
                fontWeight="600"
                style={{ width: '240px', marginRight: '30px' }}
              >
                Delete Account
              </PrimaryButton>
            </ButtonRow>
          </Fragment>
        )}
      </Formik>
    </Wrapper>
  );
};

export default DeleteAccount;
