import React from 'react';
import styled from 'styled-components';
import { Body, Title1 } from '../../components/Typography';
import ProfileSettingsForm from './ProfileSettingsForm/ProfileSettingsForm';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
  max-width: 360px;
`;

const BodyWrapper = styled.div`
  margin: 0 10%;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-gap: 40px;
  display: grid;
  grid-template-columns: auto 1fr;
  margin-top: 25px;
  @media (max-width: 768px) {
    grid-gap: 24px;
    display: grid;
    grid-template-columns: 1fr;
    margin: 10px 0;
  }
`;

const PageTitle = styled(Title1)`
  text-transform: ${(props) => props.theme.pageTitleTextTransform};
  font-weight: 600;
  width: max-content;
  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
    border-bottom: ${(props) => props.categoriesBarSelectedBorderColorWidth}
      solid ${(props) => props.theme.categoriesBarSelectedBorderColor};
  }
`;

const ProfileSettings = ({
  customerProfile,
  handleSubmit,
  handleCancel,
  editProfileSettingsIsSuccessful,
  profileSettingsFormIsEnabled,
  setProfileSettingsFormIsEnabled,
  requestingEditProfileSettings,
  editToggleValue,
  isBirthdayEnabled,
  setChangePasswordIsVisible,
  setDeleteAccountIsVisible,
  showNewForm,
  setShowNewForm,
  profileSettingsNewFormIsEnabled,
  setProfileSettingsNewFormIsEnabled,
  saveBirthday,
  birthdayModalIsVisible,
  requestingSaveBirthday,
  openBirthdayModal,
  closeBirthdayModal,
}) => {
  return (
    <ContentWrapper>
      {/* <BodyWrapper>
        <Body>My Account / Profile Settings</Body>
      </BodyWrapper> */}
      <PageTitle>Account Settings</PageTitle>
      <Wrapper>
        {customerProfile && (
          <ProfileSettingsForm
            customerProfile={customerProfile}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
            editProfileSettingsIsSuccessful={editProfileSettingsIsSuccessful}
            profileSettingsFormIsEnabled={profileSettingsFormIsEnabled}
            setProfileSettingsFormIsEnabled={setProfileSettingsFormIsEnabled}
            isRequesting={requestingEditProfileSettings}
            setChangePasswordIsVisible={setChangePasswordIsVisible}
            setDeleteAccountIsVisible={setDeleteAccountIsVisible}
            showNewForm={showNewForm}
            setShowNewForm={setShowNewForm}
            profileSettingsNewFormIsEnabled={profileSettingsNewFormIsEnabled}
            setProfileSettingsNewFormIsEnabled={
              setProfileSettingsNewFormIsEnabled
            }
            editToggleValue={editToggleValue}
            isBirthdayEnabled={isBirthdayEnabled}
            saveBirthday={saveBirthday}
            birthdayModalIsVisible={birthdayModalIsVisible}
            requestingSaveBirthday={requestingSaveBirthday}
            openBirthdayModal={openBirthdayModal}
            closeBirthdayModal={closeBirthdayModal}
          />
        )}
      </Wrapper>
    </ContentWrapper>
  );
};

export default ProfileSettings;
