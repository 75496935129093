import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { Title2 } from "../../components/Typography";
import { ButtonRow, PrimaryButton } from "../../components/Buttons";
import { Formik } from "formik";
import PasswordField from "../../layout/components/PasswordField";
import { withStyles } from "@material-ui/core/styles";
import appConfig from "../../../app-config";
const Wrapper = styled.div`
  width: 400px;
  padding: 20px;
  background: white;
  margin: auto;
  font-family: ${(props) => props.theme.primaryFontFontFamily};

  @media (max-width: 786px) {
    padding: 24px 20px;
    width: 100%;
  }
  display: grid;
  grid-gap: 20px;
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const Cancel = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  background-color: rgba(81, 78, 89, 0.63);

  right: 8px;
  top: 8px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const styles = (theme) => ({
  input: {
    fontFamily:
      appConfig.appSource === "Aahar"
        ? theme.typography.fontFamily.secondary
        : theme.typography.fontFamily.primary,
  },
});

const ChangePassword = ({
  setChangePasswordIsVisible,
  isRequesting,
  postChangePassword,
  minPasswordLength,
  changePasswordErrors,
  classes,
}) => {
  const [oldPasswordVisibility, setOldPasswordVisibility] = useState(false);
  const [newPasswordVisibility, setNewPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(
    false
  );
  const [minLengthCheck, setMinLengthCheck] = useState(false);
  const [oneNumberCheck, setOneNumberCheck] = useState(false);
  const [oneSymbolCheck, setOneSymbolCheck] = useState(false);
  const [oneUpperCheck, setOneUpperCheck] = useState(false);
  const [oneLowerCheck, setOneLowerCheck] = useState(false);
  return (
    <Wrapper>
      <TitleWrapper>
        <Title2 id="ChangePassword-Title">Change Your Password</Title2>
        <Cancel
          id="ChangePassword-TogglePassword"
          onClick={() => setChangePasswordIsVisible(false)}
        >
          <i className="fal fa-times" />
        </Cancel>
      </TitleWrapper>
      <Formik
        validate={(values) => {
          let errors = {};

          if (values.current_password == values.new_password) {
            errors.new_password = "New password cannot be same as old password";
          }
          if (values.new_password.length < minPasswordLength) {
            errors.new_password = `Password must be a minimum of ${minPasswordLength} characters`;
            setMinLengthCheck(false);
          } else {
            setMinLengthCheck(true);
          }
          if (!new RegExp("[^A-Za-z0-9]").test(values.new_password)) {
            errors.new_password = "Password must contain at least one symbol";
            setOneSymbolCheck(false);
          } else {
            setOneSymbolCheck(true);
          }
          if (!new RegExp("[0-9]").test(values.new_password)) {
            errors.new_password = "Password must contain at least one digit";
            setOneNumberCheck(false);
          } else {
            setOneNumberCheck(true);
          }
          if (!new RegExp("(?=.*[a-z])").test(values.new_password)) {
            errors.new_password =
              "Password must contain at least one lowercase letter";
            setOneLowerCheck(false);
          } else {
            setOneLowerCheck(true);
          }
          if (!new RegExp("(?=.*[A-Z])").test(values.new_password)) {
            errors.new_password =
              "Password must contain at least one uppercase letter";
            setOneUpperCheck(false);
          } else {
            setOneUpperCheck(true);
          }
          if (values.new_password != values.confirm_password) {
            errors.confirm_password = "Does not match new password";
          }

          if (!values.current_password) {
            errors.current_password = "Required";
          }
          if (!values.new_password) {
            errors.new_password = "Required";
          }
          if (!values.confirm_password) {
            errors.confirm_password = "Required";
          }
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          dirty,
        }) => (
          <Fragment>
            <PasswordField
              id="ChangePassword-OldPassword"
              label="Old Password"
              name="current_password"
              autoComplete="off"
              value={values.current_password}
              showPassword={oldPasswordVisibility}
              setShowPassword={setOldPasswordVisibility}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.current_password && errors.current_password}
              error={
                touched.current_password && errors.current_password
                  ? true
                  : false
              }
              classes={classes}
            />
            <PasswordField
              id="ChangePassword-NewPassword"
              label="New Password"
              name="new_password"
              autoComplete="off"
              value={values.new_password}
              showPassword={newPasswordVisibility}
              setShowPassword={setNewPasswordVisibility}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.new_password && errors.new_password}
              error={touched.new_password && errors.new_password ? true : false}
              complexityProps={{
                minLengthCheck,
                oneNumberCheck,
                oneSymbolCheck,
                oneUpperCheck,
                oneLowerCheck,
                minPasswordLength,
                marginTop: "-12px",
              }}
              classes={classes}
            />
            <PasswordField
              id="ChangePassword-ConfirmPassword"
              label="Confirm New Password"
              name="confirm_password"
              autoComplete="off"
              value={values.confirm_password}
              showPassword={confirmPasswordVisibility}
              setShowPassword={setConfirmPasswordVisibility}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.confirm_password && errors.confirm_password}
              error={
                touched.confirm_password && errors.confirm_password
                  ? true
                  : false
              }
              classes={classes}
            />
            <ButtonRow justifyContent="flex-center">
              <PrimaryButton
                id="ChangePassword-SubmitButton"
                disabled={Object.keys(errors).length || !dirty}
                type="submit"
                onClick={() => postChangePassword(values)}
                buttonWidth="240px"
                buttonHeight="56px"
                buttonBorderRadius="10px"
                fontWeight="600"
                style={{ width: "240px", margin: "0 auto" }}
              >
                Save
              </PrimaryButton>
            </ButtonRow>
          </Fragment>
        )}
      </Formik>
    </Wrapper>
  );
};

export default withStyles(styles)(ChangePassword);
